import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BiijHeader from "../components/biijheader"
import SubHeader from "../components/subheader"
import NewsSideBar from "../components/newssidebar"

import layoutStyles from "../components/layout.module.css"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
      <div className={`container ${layoutStyles.mainwell}`}>
        <BiijHeader></BiijHeader>
        <SubHeader></SubHeader>
      <div class="row">
        <NewsSideBar></NewsSideBar>
      </div>
    </div>

  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

{/* <Layout location={location} title={siteTitle}>
<SEO title="404: Not Found" />
<h1>Not Found</h1>
<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
</Layout> */}
